import { environment } from '../../environments/environment';

export const URLS = Object({
   'loginapi': `${environment.apiUrl}/auth/login`,
   'kycapi': `${environment.apiUrl}/kyc/applicants`,
   'kycstatusapi': `${environment.apiUrl}/kyc/status/`,
   'kycapplicant': `${environment.apiUrl}/kyc/applicant/`,
   'logoutapi': `${environment.apiUrl}/auth/logout`,
   'changepassword': `${environment.apiUrl}/preference/change-password`,
   'userById': `${environment.apiUrl}/user/details`,
   'userByEmail': `${environment.apiUrl}/user/details`,
   'migrateUser': `${environment.apiUrl}/user/migration-tool`,
   'userByTicket': `${environment.apiUrl}/user/details`,
   'terminateAcc': `${environment.apiUrl}/user/terminate`,
   'verifyAccSkrill': `${environment.extApiUrl}/transaction/skrill-check`,
   'verifyAccBank': `${environment.extApiUrl}/transaction/iban`,
   'transactionHistoryapi': `${environment.apiUrl}/transaction/list`,
   'bankTransferPayinsApi': `${environment.apiUrl}/transaction/list`,
   'migrationToolListapi': `${environment.apiUrl}/user/past-svd-transactions`,
   'cryptoTransactionapi': `${environment.apiUrl}/transaction/list-crypto`,
   'svdTransactionapi': `${environment.apiUrl}/transaction/list-svd`,
   'custodyreportapi': `${environment.apiUrl}/transaction/custody-report`,
   'suspectUpdate': `${environment.apiUrl}/kyc/report_suspect`,
   'remidiationUpdate': `${environment.apiUrl}/kyc/sumsub_kyc_lvl`,
   'onBoardingUpdate': `${environment.apiUrl}/kyc/sumsub_kyc_lvl`, ///// Need to change
   'fmareportapi': `${environment.apiUrl}/kyc/reports/fma_reporting`,
   'statusChangeApi': `${environment.apiUrl}/transaction/process-btfr`,
   'pausedUserApi': `${environment.apiUrl}/user_pause/list`,
   'resumeUser': `${environment.apiUrl}/user_pause/add`,
   'pauseHistoryapi': `${environment.apiUrl}/user_pause/history`,
   'cryptoAdjustmentApi': `${environment.apiUrl}/transaction/crypto-adjustment/list`,
   'addCryptoApi': `${environment.apiUrl}/transaction/crypto-adjustment/add`,
   'addBtcTransfer': `${environment.apiUrl}/transaction/transferBtc`,
   'kycManualReview': `${environment.apiUrl}/kyc/manual_review`,
});

// tslint:disable-next-line:max-line-length
export const SECRET_KEY = `${environment.rsa_public_key}`;
