import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtInterceptor, ErrorInterceptor } from './share/helpers/index';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';

import { ConfirmDialogComponent } from './share/components/confirm-dialog/confirm-dialog.component';
import { ProofsDialogComponent } from './share/components/proofs-dialog/proofs-dialog.component';
import { UserDialogComponent } from './share/components/user-dialog/user-dialog.component';
import { SuspectDialogComponent } from './share/components/suspect-dialog/suspect-dialog.component';
import { RemidiationDialogComponent } from './share/components/remidiation-dialog/remidiation-dialog.component';
import { OnBoardingDialogComponent } from './share/components/onBoarding-dialog/onBoarding-dialog.component';
import { ResumeDialogComponent } from './share/components/resume-dialog/resume-dialog.component';

import { MigrateUserDialogComponent } from './share/components/migrate-user-dialog/migrate-user-dialog.component';
import { AccTerminateDialogComponent } from './share/components/accTerminate-dialog/accTerminate-dialog.component';
import { AddCryptoDialogComponent } from './share/components/addCrypto-dialog/addCrypto-dialog.component';
import { QuestionnaireDialogComponent } from './share/components/questionnaire-dialog/questionnaire-dialog.component';
import { ApplicantDataDialogComponent } from './share/components/applicantData-dialog/applicantData-dialog.component';
import { MatGridListModule } from '@angular/material/grid-list';
import {MatSelectModule} from '@angular/material/select';

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    /* for development
    return new TranslateHttpLoader(
        http,
        '/start-javascript/sb-admin-material/master/dist/assets/i18n/',
        '.json'
    );*/
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
    declarations: [AppComponent, ConfirmDialogComponent, ProofsDialogComponent, UserDialogComponent, SuspectDialogComponent, RemidiationDialogComponent, OnBoardingDialogComponent, MigrateUserDialogComponent, AccTerminateDialogComponent, AddCryptoDialogComponent, QuestionnaireDialogComponent, ApplicantDataDialogComponent, ResumeDialogComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        OverlayModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatSnackBarModule,
        MatSelectModule,
        MatTabsModule,
        MatGridListModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
],
  exports: [
    MatButtonModule, MatDialogModule
  ],
    bootstrap: [AppComponent]
})
export class AppModule {}
