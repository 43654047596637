<!-- <h1 mat-dialog-title>
    Suspect Status
</h1> -->

<div mat-dialog-content class="search-tab-wrapper">
    <form class="example-form" [formGroup]="remidiationForm" (ngSubmit)="onSubmit()">
        <div>
            <mat-form-field>
                <mat-select #matSelect placeholder="KYC level" formControlName="kycLevel">
                    <mat-option *ngFor="let kycOption of kycLevellist" [value]="kycOption.key">
                        {{kycOption.value}}
                    </mat-option>
                    <!-- <mat-option value="B">Basic</mat-option>
                    <mat-option value="EXT">extended-kyc-level</mat-option> -->
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <input matInput formControlName="kycSwitchReason" value="" placeholder="KYC Switch Reason">
            </mat-form-field>
            <div *ngIf="(submitted && form.kycSwitchReason.errors) || (form.kycSwitchReason.errors && (form.kycSwitchReason.dirty || form.kycSwitchReason.touched))" class="error">
                <p class="error" *ngIf="form.kycSwitchReason.errors.required">KYC Switch Reason is required</p>
            </div>
        </div>

        <div class="btn-toolbar">
            <button mat-raised-button color="primary" type="submit" mat-button>
                Submit
            </button>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="warn" mat-button (click)="onCancel();" style="margin-left: 10px;">
        Cancel
    </button>
</div>