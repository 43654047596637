<h1 mat-dialog-title>
    Questionnaire
</h1>

<div mat-dialog-content>
    <div *ngIf="!questionnaireData">
        <p style="text-align:center;">No Data Available</p>
    </div>
    <div class="data-block" *ngIf="questionnaireData">
        <div class="sections" *ngFor="let section of sectionList;">
            <label class="title">{{section.name}}</label>
            <mat-grid-list cols="4" rowHeight="4:1" *ngFor="let item of section.item">
                <mat-grid-tile colspan="2"><label style="text-transform: capitalize;">{{item.key}}:</label></mat-grid-tile>
                <mat-grid-tile colspan="2"><label><span *ngIf="!item.value">-</span>{{item.value}}</label></mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
</div>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>