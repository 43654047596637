import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer} from '@angular/platform-browser';
import {FormControl, FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { AuthServices } from '../../services/index';
import { Config } from 'protractor';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-questionnaire-dialog',
  templateUrl: './questionnaire-dialog.component.html',
  styleUrls: ['./questionnaire-dialog.component.css']
})
export class QuestionnaireDialogComponent implements OnInit{

  questionnaireData: any = [];
  sections: any;
  items: any;
  sectionList = [];
  itemList = [];
  
  constructor(
    private authServices: AuthServices,
    public sanitizer: DomSanitizer, 
    private router: Router,    
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: QuestionnaireDialogModel,
    public dialogRef: MatDialogRef<QuestionnaireDialogComponent>) {
      this.questionnaireData = data;
    }


  ngOnInit(): void {

    for (let index = 0; index < this.questionnaireData.length; index++) {
      this.sections = this.questionnaireData[index].sections;
      let sectionProperties = Object.keys(this.sections);
      let i = 0;
      for (const prop of sectionProperties ) { 
          this.sectionList.push(this.sections[prop]);
          this.sectionList[i]['name'] = prop;
          this.items = this.sectionList[i].items;
          let itemProperties = Object.keys(this.items);
          let j = 0;
          this.itemList = [];
          for (const prop of itemProperties ) { 
              this.itemList.push(this.items[prop]);
              this.itemList[j]['key'] = prop;
              j++;
          }
          this.sectionList[i]['item'] = this.itemList;
          i++;
      }
      
    }

  }

  openSnackBar(message: any) {
    this._snackBar.open(message, '', {
      duration: 3000,
    });
  }

}

export class QuestionnaireDialogModel {
  constructor(public questionnaireData: any) {
  }
}
