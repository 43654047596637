import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer} from '@angular/platform-browser';
import {FormControl, FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { AuthServices } from '../../services/index';
import { Config } from 'protractor';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-onBoarding-dialog',
  templateUrl: './onBoarding-dialog.component.html',
  styleUrls: ['./onBoarding-dialog.component.css']
})
export class OnBoardingDialogComponent implements OnInit{
  user: any;
  onBoardingForm: any;
  submitted: boolean = false;
  userId: any;
  level: any;
  reason: any;
  kycLevelOptions = [];
  kycLevellist = [];

  // get form control
  get form() {
    return this.onBoardingForm.controls;
  }
  // public showTable = false;

  constructor(
    private authServices: AuthServices,
    public sanitizer: DomSanitizer, 
    private router: Router,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<OnBoardingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OnBoardingDialogModel
  ) {
    this.userId = data['id'];
    this.level = data['level'];
    this.reason = data['reason'];
    this.kycLevelOptions = data['kycLevelOptions'];
  }

  // @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

    // form controls
    let onBoardingFormcontrols = {
      kycLevel: new FormControl('',),
      kycSwitchReason: new FormControl('', [Validators.required]),
    };

    // create a form
    this.onBoardingForm = new FormGroup(onBoardingFormcontrols);    

    this.onBoardingForm.controls['kycLevel'].value = this.level;
    this.onBoardingForm.controls['kycSwitchReason'].value = this.reason;
    this.kycLevellist = this.kycLevelOptions;
  }

  onSubmit() {

      if (this.onBoardingForm.invalid) {
        return;
      }

      const dataParams = {
        // applicant_id: this.applicantId,
	      kyc_lvl_sw_reason: this.onBoardingForm.value.kycSwitchReason.trim(),
        sumsub_kyc_level: this.onBoardingForm.value.kycLevel.trim()
      };
      
      this.authServices.updateOnBoarding(dataParams, this.userId).subscribe(
        (resp: Config) => {
          if (resp.code === 200) {
            this.dialogRef.close({params: dataParams, click: "submitClick"});
            this.openSnackBar(resp.message);
          } else{
            // alert(resp.data);
            // error.innerHTML = resp.data;
          }
        },
        (err) => {
          console.log('status --->' + err.error.error);
        }
      );
  }

  onCancel(): void{
    this.dialogRef.close({params: null, click: "cancelClick"});
  }

  openSnackBar(message: any) {
    this._snackBar.open(message, '', {
      duration: 3000,
    });
  }
}


export class OnBoardingDialogModel {
  constructor(public data: any) {
  }
}
