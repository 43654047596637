import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer} from '@angular/platform-browser';
import {FormControl, FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { AuthServices } from '../../services/index';
import { Config } from 'protractor';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-migrate-user-dialog',
  templateUrl: './migrate-user-dialog.component.html',
  styleUrls: ['./migrate-user-dialog.component.css']
})
export class MigrateUserDialogComponent implements OnInit{
  title: string;
  message: any;
  email: any;
  user: any;
  emailForm: any;
  submitted: boolean = false;
  migrateMessage: any;
  // isValidEmail: boolean = true;
  // isValidTicket: boolean = true;

  // get form control
  get form() {
    return this.emailForm.controls;
  }
  // public showTable = false;

  constructor(
    private authServices: AuthServices,
    public sanitizer: DomSanitizer, 
    private router: Router,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<MigrateUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MigrateUserDialogModel
    ) {
      this.email = data;
    }

  // @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

     // form controls
    let emailFormcontrols = {
      purpose: new FormControl('', [Validators.required]),
    };

    // create a form
    this.emailForm = new FormGroup(emailFormcontrols);

  }

  openSnackBar(message: any) {
    this._snackBar.open(message, '', {
      duration: 3000,
    });
  }

  onSubmit() {
    // this.isValidEmail = false;

      if (this.emailForm.invalid) {
        return;
      }

      const dataParams = {
        email: this.email.email.trim(),
        purpose: this.emailForm.value.purpose.trim()
      };
      // const error = document.getElementById("user_error");
      
      this.authServices.getMigrateUser(dataParams).subscribe(
        (resp: Config) => {
          if (resp.code === 200) {
            this.dialogRef.close(resp.data);
            this.openSnackBar(resp.message);
          } else{
            this.migrateMessage = resp.message;
          }
        },
        (err) => {
          console.log('status --->' + err.error.error);
        }
      );
  }

}

export class MigrateUserDialogModel {

  constructor(public title: string, public message: string) {
  }
}
