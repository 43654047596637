import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment.prod';
import { AuthServices } from '../services/index';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthServices) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
    //     const currentUser = this.authenticationService.currentUserValue;
    //     let isLoggedIn = '';
    //    if (currentUser['data']) {
    //      isLoggedIn = currentUser && currentUser['data'].token;
    //    }
    const getAccessToken = this.authenticationService.getAccessToken();

        // const isLoggedIn = this.authenticationService.isLoggedIn();
       // const isApiUrl = request.url.startsWith(environment.apiUrl);
    //    const isApiUrl = request.url.indexOf('i18n');
    //    if (isLoggedIn && (isApiUrl === -1)) {
       // if (getAccessToken && isApiUrl) {
        if (getAccessToken) {
            request = request.clone({
                setHeaders: {
                   Authorization: `Bearer ${getAccessToken}`
                }
            });
        }

        return next.handle(request);
    }
}
