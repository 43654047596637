<h1 mat-dialog-title>
    {{title}}
</h1>

<div mat-dialog-content>
    <p>{{message}}</p>
</div>

<form [formGroup]="remarkForm" *ngIf="selectedValue === 'declined'">
<mat-form-field class="example-full-width">
    <input matInput placeholder="Enter Your Remarks" maxlength="500" formControlName="remarks" value="" required>
    <mat-error *ngIf="remarkForm.hasError('maxlength', 'remarks')">
        You can enter maximum 500 character
    </mat-error>
    <mat-error *ngIf="remarkForm.hasError('whitespace', 'remarks')">
        Please Enter Valid Text
    </mat-error> 
</mat-form-field>
</form>

<div mat-dialog-actions>
    <button mat-button (click)="onDismiss()">No</button>
    <button mat-raised-button color="primary" [disabled]="!remarkForm.valid && selectedValue === 'declined'" (click)="onConfirm()">Yes</button>
</div>