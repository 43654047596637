<h1 mat-dialog-title>
    Applicant Data
</h1>

<div mat-dialog-content>
    <div *ngIf="!applicantData">
        <p style="text-align:center;">No Data Available</p>
    </div>
    <div class="data-block" *ngIf="applicantData">
        <mat-grid-list cols="4" rowHeight="4:1">
            <mat-grid-tile><label>First Name:</label></mat-grid-tile>
            <mat-grid-tile colspan="3"><label>{{applicantData.firstName}}</label></mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="4" rowHeight="4:1">
            <mat-grid-tile><label>Last Name:</label></mat-grid-tile>
            <mat-grid-tile colspan="3"><label>{{applicantData.lastName}}</label></mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="4" rowHeight="4:1">
            <mat-grid-tile><label>Date of Birth:</label></mat-grid-tile>
            <mat-grid-tile colspan="3"><label>{{applicantData.dob}}</label></mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="4" rowHeight="4:1">
            <mat-grid-tile><label>Address:</label></mat-grid-tile>
            <mat-grid-tile colspan="3"><label *ngFor="let address of applicantData.addresses">{{address.buildingNumber}}, {{address.street}}, {{address.town}}, {{address.state}}, {{address.postCode}}</label></mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="4" rowHeight="4:1">
            <mat-grid-tile><label>Country:</label></mat-grid-tile>
            <mat-grid-tile colspan="3"><label>{{applicantData.country}}</label></mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="4" rowHeight="4:1">
            <mat-grid-tile><label>Birth Place:</label></mat-grid-tile>
            <mat-grid-tile colspan="3"><label>{{applicantData.placeOfBirth}}</label></mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="4" rowHeight="4:1">
            <mat-grid-tile><label>Nationality:</label></mat-grid-tile>
            <mat-grid-tile colspan="3"><label>{{applicantData.nationality}}</label></mat-grid-tile>
        </mat-grid-list>
    </div>
</div>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>