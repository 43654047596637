<h1 mat-dialog-title>
    Add Crypto Adjustment
</h1>

<div mat-dialog-content>
    <form class="example-form" [formGroup]="cryptoForm" (ngSubmit)="onCryptoSubmit()">
        <mat-form-field class="example-full-width">
            <mat-label>User Id</mat-label>
            <input id="userId" type="userId" name="userId" formControlName="userId" matInput placeholder="Enter User Id">
        </mat-form-field>
        <div *ngIf="(submitted && form.userId.errors) || (form.userId.errors && (form.userId.dirty || form.userId.touched))" class="error">
            <p class="error" *ngIf="form.userId.errors.required">User Id is required</p>
        </div>

        <mat-form-field class="example-full-width">
            <mat-label>Crypto Value</mat-label>
            <input id="cryptoValue" type="cryptoValue" name="cryptoValue" formControlName="cryptoValue" matInput placeholder="Enter Crypto Value">
        </mat-form-field>
        <div *ngIf="(submitted && form.cryptoValue.errors) || (form.cryptoValue.errors && (form.cryptoValue.dirty || form.cryptoValue.touched))" class="error">
            <p class="error" *ngIf="form.cryptoValue.errors.required">Crypto Value is required</p>
        </div>

        <mat-form-field class="example-full-width">
            <mat-label>Type</mat-label>
            <input id="type" type="type" name="type" formControlName="type" matInput placeholder="Enter Type">
        </mat-form-field>
        <div *ngIf="(submitted && form.type.errors) || (form.type.errors && (form.type.dirty || form.type.touched))" class="error">
            <p class="error" *ngIf="form.type.errors.required">Type is required</p>
        </div>

        <mat-form-field class="example-full-width">
            <mat-label>Comment</mat-label>
            <input id="comment" type="comment" name="comment" formControlName="comment" matInput placeholder="Enter Comment">
        </mat-form-field>
        <div *ngIf="(submitted && form.comment.errors) || (form.comment.errors && (form.comment.dirty || form.comment.touched))" class="error">
            <p class="error" *ngIf="form.comment.errors.required">Comment is required</p>
        </div>        

        <div class="btn-toolbar">
            <button mat-raised-button color="primary" type="submit" [disabled]="addBtnDisabled" mat-button>Add</button>
            <button mat-raised-button color="default" mat-button (click)="cancel()">Cancel</button>
        </div>
    </form>
</div>
<!-- <div mat-dialog-actions style="float: right;">
    <button mat-raised-button color="primary" type="submit" mat-button>Terminate now</button>
</div> -->