import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer} from '@angular/platform-browser';
import {FormControl, FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { AuthServices } from '../../services/index';
import { Config } from 'protractor';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

/** Error when invalid control is dirty, touched, or submitted. */
// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     const isSubmitted = form && form.submitted;
//     return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
//   }
// }

@Component({
  selector: 'app-accTerminate-dialog',
  templateUrl: './accTerminate-dialog.component.html',
  styleUrls: ['./accTerminate-dialog.component.css']
})
export class AccTerminateDialogComponent implements OnInit{
  title: string;
  message: any;
  user: any;
  terminateForm: any;
  submitted: boolean = false;
  public skrillData: any;
  public payeeBank: any;
  public paymentMethod: any;
  public verifySuccessMessage: boolean = false;
  public verifyFailMessage: boolean = false;
  public trminateAccBtnDisabled: boolean = false;

   // get form control
  get form() {
    return this.terminateForm.controls;
  }

  constructor(
    private authServices: AuthServices,
    public sanitizer: DomSanitizer, 
    private router: Router,    
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AccTerminateDialogComponent>) {
      // this.title = data.title;
      // this.message = data.message;
    }

  ngOnInit(): void {

    // form controls
    let terminateFormcontrols = {
      email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
      iban: new FormControl('',),
      ticket: new FormControl('', [Validators.required]),
      remark: new FormControl('',),
      payout: new FormControl('',),
    };

    // create a form
    this.terminateForm = new FormGroup(terminateFormcontrols);

  }

  payoutChange(event){
    if(event === undefined){
      this.verifyFailMessage = false;
      this.verifySuccessMessage = false;
      this.trminateAccBtnDisabled = false;
    } else {
      this.trminateAccBtnDisabled = true;
    }
  }

  verifyAcc(){    

    this.paymentMethod = this.terminateForm.value.payout;
    
    if(this.paymentMethod === 'skrill'){
      const dataParams = {
        recipient_email: this.terminateForm.value.iban,
      };
      // const token = this.authServices.getAccessToken();
      this.authServices.VerifyAccSkrill(dataParams).subscribe(
        (resp: Config) => {
          if (resp.code === 200) {
            this.skrillData = resp.data;
            this.verifySuccessMessage = true;
            this.verifyFailMessage = false;
            this.trminateAccBtnDisabled = false;
          } else{
            this.verifyFailMessage = true;
            this.verifySuccessMessage = false;
            this.trminateAccBtnDisabled = true;
          }
        },
        (err) => {
          console.log('status --->' + err.error.error);
        }
      );
    }
    else{ 
      const dataParams = this.terminateForm.value.iban;
      // const token = this.authServices.getAccessToken();
      this.authServices.getVerifyAccBank(dataParams).subscribe(
        (resp: Config) => {
          if (resp.code === 200) {
            this.payeeBank = resp.data.bankSwiftCode;
            this.verifySuccessMessage = true;
            this.verifyFailMessage = false;
            this.trminateAccBtnDisabled = false;
          } else{
            this.verifyFailMessage = true;
            this.verifySuccessMessage = false;
            this.trminateAccBtnDisabled = true;
          }
        },
        (err) => {
          console.log('status --->' + err.error.error);
        }
      );
    }
  }

  openSnackBar(message: any) {
    this._snackBar.open(message, '', {
      duration: 3000,
    });
  }
  
  onTerminateSubmit() {
    // this.isValidEmail = false;

      if (this.terminateForm.invalid) {
        return;
      }
      
      const dataParams = {
        email: this.terminateForm.value.email.trim(),
        ticket_no: this.terminateForm.value.ticket
      };
      if(this.terminateForm.value.payout){
        dataParams['payout_method'] = this.terminateForm.value.payout
      }
      if(this.terminateForm.value.iban){
        dataParams['iban_or_skrill_no'] = this.terminateForm.value.iban
      }
      if(this.terminateForm.value.remark){
        dataParams['remarks'] = this.terminateForm.value.remark
      }
      if(this.paymentMethod === 'skrill'){
        dataParams['skrill_usa'] = this.skrillData
      } else if(this.paymentMethod === 'bank'){
        dataParams['payee_bank'] = this.payeeBank
      }

      // const error = document.getElementById("user_error");
      
      this.authServices.TerminateAcc(dataParams).subscribe(
        (resp: Config) => {
          if (resp.code === 200) {
            this.dialogRef.close();
            this.openSnackBar(resp.message);
          } else{
            // error.innerHTML = resp.data;
          }
        },
        (err) => {
          console.log('status --->' + err.error.error);
        }
      );
  }


}

export class AccTerminateDialogModel {

  constructor(public title: string, public message: string) {
  }
}
