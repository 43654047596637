<h1 mat-dialog-title>
    Migration Tool
</h1>

<div mat-dialog-content class="search-tab-wrapper">
    <form class="example-form" [formGroup]="emailForm" (ngSubmit)="onSubmit()">
        <mat-form-field class="example-full-width">
            <mat-label>Purpose</mat-label>
            <input id="purpose" type="text" name="purpose" formControlName="purpose" matInput placeholder="Enter Purpose">
        </mat-form-field>
        <div *ngIf="(submitted && form.purpose.errors) || (form.purpose.errors && (form.purpose.dirty || form.purpose.touched))" class="error">
            <p class="error" *ngIf="form.purpose.errors.required">Purpose is required</p>
        </div>
        <!-- <div id="user_error"></div> -->
        <div class="btn-toolbar">
            <button mat-raised-button color="primary" type="submit" mat-button>
                Submit
            </button>
            <button mat-button [mat-dialog-close]="true" color="none" style="margin-left: 10px;">Cancel</button>
        </div>

        <div>
            <p class="migrateMessage">{{migrateMessage}}</p>
        </div>
    </form>
</div>