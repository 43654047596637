<h1 mat-dialog-title>
    Search User
</h1>

<div mat-dialog-content class="search-tab-wrapper">
    <mat-tab-group mat-align-tabs="start" animationDuration="0ms">
        <mat-tab label="By User Id">
            <form class="example-form" [formGroup]="idForm" (ngSubmit)="onIdSubmit()">
                <mat-form-field class="example-full-width">
                    <mat-label>User ID</mat-label>
                    <input id="id" type="text" name="id" formControlName="id" matInput placeholder="Enter User Id">
                </mat-form-field>
                <div *ngIf="(submitted && f.id.errors) || (f.id.errors && (f.id.dirty || f.id.touched))" class="error">
                    <p class="error" *ngIf="f.id.errors.required">User Id is required</p>
                </div>
                <!-- <div id="user_error"></div> -->
                <div class="btn-toolbar">
                    <button mat-raised-button color="primary" type="submit" mat-button>
                        Submit
                    </button>
                </div>
            </form>
        </mat-tab>
        <mat-tab label="By Email">
            <form class="example-form" [formGroup]="emailForm" (ngSubmit)="onEmailSubmit()">
                <mat-form-field class="example-full-width">
                    <mat-label>User Email</mat-label>
                    <input id="email" type="email" name="email" formControlName="email" matInput placeholder="Enter User Email">
                </mat-form-field>
                <div *ngIf="(submitted && form.email.errors) || (form.email.errors && (form.email.dirty || form.email.touched))" class="error">
                    <p class="error" *ngIf="form.email.errors.required">Email is required</p>
                    <p class="error" *ngIf="form.email.errors.pattern">Invalid email</p>
                </div>
                <!-- <div id="user_error"></div> -->
                <div class="btn-toolbar">
                    <button mat-raised-button color="primary" type="submit" mat-button>
                        Submit
                    </button>
                </div>
            </form>
        </mat-tab>
        <mat-tab label="By Ticket No">
            <form class="example-form" [formGroup]="ticketForm" (ngSubmit)="onTicketSubmit()">
                <mat-form-field class="example-full-width">
                    <mat-label>Ticket No</mat-label>
                    <input matInput id="ticket" type="text" name="ticket" formControlName="ticket" matInput placeholder="Enter Ticket No">
                </mat-form-field>
                <!-- <div id="user_error"></div> -->
                <!-- <div *ngIf="(submitted && form.ticket.errors) || (form.ticket.errors && (form.ticket.dirty || form.ticket.touched))" class="error">
                    <p class="error" *ngIf="form.ticket.errors.required">Ticket is required</p>
                </div> -->
                <div class="btn-toolbar">
                    <button mat-raised-button color="primary" type="submit" mat-button>
                    Submit
                </button>
                </div>
            </form>
        </mat-tab>
    </mat-tab-group>
</div>
<!-- 
<div mat-dialog-actions style="float: right;">
    <button mat-raised-button color="primary" type="submit" (click)="onEmailSubmit()" mat-button>Submit</button>
</div> -->