<h1 mat-dialog-title>
    {{modalName === 'resume' ? 'Resume' : 'Pause'}} User
</h1>

<div mat-dialog-content>
    <form class="example-form" [formGroup]="resumeForm" (ngSubmit)="submit(modalName)">
        <mat-form-field class="example-full-width">
            <mat-label>Comment</mat-label>
            <input matInput id="comment" type="text" name="comment" formControlName="comment" matInput placeholder="Enter Comments">
        </mat-form-field>

        <div class="btn-toolbar">
            <button mat-raised-button color="warn" mat-dialog-close mat-button style="margin-left: 10px;">
                Cancel
            </button>
            <button mat-raised-button color="primary" type="submit" mat-button style="margin-left: 10px;">
                Confirm
            </button>
        </div>
    </form>
</div>