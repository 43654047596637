import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthServices } from '../../services/index';
import { Config } from 'protractor';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-resume-dialog',
  templateUrl: './resume-dialog.component.html',
  styleUrls: ['./resume-dialog.component.css']
})
export class ResumeDialogComponent implements OnInit {

  resumeForm: any;
  modalData: any;
  modalName: any;

  get form() {
    return this.resumeForm.controls;
  }

  constructor(
    private authServices: AuthServices,
    public sanitizer: DomSanitizer,
    private router: Router,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: ResumeDialogModel,
    public dialogRef: MatDialogRef<ResumeDialogComponent>) {
    // this.resumeData = data;
    this.modalData = data;
  }

  ngOnInit(): void {
    this.modalName = this.modalData.for;
    // form controls
    let resumeFormcontrols = {
      comment: new FormControl('', [Validators.required]),
    };

    // create a form
    this.resumeForm = new FormGroup(resumeFormcontrols);
  }

  openSnackBar(message: any) {
    this._snackBar.open(message, '', {
      duration: 3000,
    });
  }

  submit(name) {

    if (this.resumeForm.invalid) {
      return;
    }

    const dataParams = {
      user_id: this.modalData.userId,
      comment: this.resumeForm.value.comment
    };
    if (name === 'resume' || name === 'resume_manual_review') {
      dataParams['status'] = 'RESUMED';
    } else {
      dataParams['status'] = 'PAUSED';
    }
    if (name === 'resume' || name === 'pause') {
      this.authServices.ResumeUser(dataParams).subscribe(
        (resp: Config) => {
          if (resp.code === 200) {
            this.dialogRef.close({ click: "submitClick" });
            this.openSnackBar(resp.message);
          } else {
            // error.innerHTML = resp.data;
          }
        },
        (err) => {
          console.log('status --->' + err.error.error);
        }
      );
    }

    if (name === 'resume_manual_review') {
      this.authServices.getKycManualReview(dataParams).subscribe(
        (resp: Config) => {
          if (resp.code === 200) {
            this.dialogRef.close({ click: "submitClick" });
            this.openSnackBar(resp.message);
          } else {
            // error.innerHTML = resp.data;
          }
        },
        (err) => {
          console.log('status --->' + err.error.error);
        }
      );
    }

  }



  onCancel() {
    this.dialogRef.close();
  }

}

export class ResumeDialogModel {
  constructor(public data: any) {
  }
}
