import { Injectable } from '@angular/core';
import { User } from '../../user';
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { debug } from 'util';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URLS } from '../../_config/api.config';
import { debug } from 'console';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthServices {
  loginApi = URLS.loginapi;
  kycapi = URLS.kycapi;
  logoutapi = URLS.logoutapi;
  changepasswordapi = URLS.changepassword;
  kycstatusapi = URLS.kycstatusapi;
  kycapplicant = URLS.kycapplicant;
  userById = URLS.userById;
  userByEmail = URLS.userByEmail;
  migrateUser = URLS.migrateUser;
  userByTicket = URLS.userByTicket;
  terminateAcc = URLS.terminateAcc;
  verifyAccSkrill = URLS.verifyAccSkrill;
  verifyAccBank = URLS.verifyAccBank;
  transactionHistoryapi = URLS.transactionHistoryapi;
  bankTransferPayinsApi = URLS.bankTransferPayinsApi;
  statusChangeApi = URLS.statusChangeApi;
  migrationToolListapi = URLS.migrationToolListapi;
  cryptoTransactionapi = URLS.cryptoTransactionapi;
  svdTransactionapi = URLS.svdTransactionapi;
  custodyReportApi = URLS.custodyreportapi;
  fmaReportApi = URLS.fmareportapi;
  suspectupdateapi = URLS.suspectUpdate;
  remidiationupdateapi = URLS.remidiationUpdate;
  onBoardingupdateapi = URLS.onBoardingUpdate;
  pausedUserApi = URLS.pausedUserApi;
  resumeUser = URLS.resumeUser;
  pauseHistoryapi = URLS.pauseHistoryapi;
  cryptoAdjustmentApi = URLS.cryptoAdjustmentApi;
  addCryptoApi = URLS.addCryptoApi;
  addBtcTransfer = URLS.addBtcTransfer;
  kycManualReview = URLS.kycManualReview;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public activeTabTotalRecords;
  public suspectTabTotalRecords;
  public terminatedTabTotalRecords;
  public remidiationTabTotalRecords;
  public onBoardingTabTotalRecords;
  public pausedUsersTabTotalRecords;

  constructor(private http: HttpClient) {
    const currentUserInfo = localStorage.getItem('current_user');
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(currentUserInfo)
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    // return this.currentUserSubject.value;
    return JSON.parse(localStorage.getItem('current_user'));
  }

  public getAccessToken() {
    const accessToken = localStorage.getItem('admin_access_token');
    if (accessToken) {
      return accessToken;
    }
    return null;
  }

  public isLoggedIn() {
    return localStorage.getItem('admin_access_token') !== null;
  }

  getLoginResponse(dataParams: any) {
    return this.http.post<any>(this.loginApi, dataParams).pipe(
      map((user) => {
        if (user && user.code === 200) {
          localStorage.setItem('admin_access_token', user.data.access_token);
          localStorage.setItem('client_access_token', user.data.client_access_token);
          localStorage.setItem('current_user', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        } else {
          return user;
        }
      })
    );
  }

  // getKycListApi(dataParams: any): Observable<{}> {
  //   return this.http.get(`${this.kycapi}`, {
  //     params: dataParams,
  //   });
  // }

  getActiveKycListApi(dataParams: any) {
    return this.http.post<any>(this.kycapi, dataParams).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  getPausedUsersKycListApi(dataParams: any) {
    return this.http.post<any>(this.pausedUserApi, dataParams).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  exportCsv(dataParams: any) {
    return this.http.post<any>(this.kycapi, dataParams, { headers: {}, responseType: 'blob' as 'json' });
  }

  pausedUserExportCsv(dataParams: any) {
    return this.http.post<any>(this.pausedUserApi, dataParams, { headers: {}, responseType: 'blob' as 'json' });
  }

  getTerminatedKycListApi(dataParams: any) {
    return this.http.post<any>(this.kycapi, dataParams).pipe(
      map((resp) => {
        return resp;
      })
    );
  }  

  getKycListApi(dataParams: any) {
    return this.http.post<any>(this.kycapi, dataParams).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  getRemidiationKycListApi(dataParams: any) {
    return this.http.post<any>(this.kycapi, dataParams).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  getOnBoardingKycListApi(dataParams: any) {
    return this.http.post<any>(this.kycapi, dataParams).pipe(
      map((resp) => {
        return resp;
      })
    );
  }
  
  // postKycDetailsApi(dataParams: any) {
  //   const headers = { 'content-type': 'application/json'}  
 
  // //  const params = new HttpParams()
  // //     .set('para1', "value1")
  // //     .set('para2',"value2");
  // //   const body=JSON.stringify(person);
 
  //   return this.http.post<any>(this.kycapi,{'headers':headers, 'params': dataParams})
       
  // }
      

  getTransactionHistoryApi(dataParams: any): Observable<{}> {
    return this.http.get(`${this.transactionHistoryapi}`, {
      params: dataParams,
    });
  }

  getPauseHistoryApi(dataParams: any): Observable<{}> {
    return this.http.get(`${this.pauseHistoryapi}`, {
      params: dataParams,
    });
  }

  getBankTransferPayinsApi(dataParams: any): Observable<{}> {
    return this.http.get(`${this.bankTransferPayinsApi}`, {
      params: dataParams,
    });
  }

  getStatusChangeApi(dataParams: any): Observable<{}> {
    return this.http.get(`${this.statusChangeApi}`, {
      params: dataParams,
    });
  }

  getCryptoAdjustmentApi(dataParams: any): Observable<{}> {
    return this.http.post(`${this.cryptoAdjustmentApi}`, dataParams);
  }  

  cryptoAdjustmentExportCsv(dataParams: any) {
    return this.http.post<any>(this.cryptoAdjustmentApi, dataParams, { headers: {}, responseType: 'blob' as 'json' });
  }

  addCrypto(dataParams: any) {
    return this.http.post<any>(this.addCryptoApi, dataParams).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  getCryptoTransactionHistory(dataParams: any): Observable<{}> {
    return this.http.get(`${this.cryptoTransactionapi}`, {
      params: dataParams,
    });
  }

  getDownloadCryptoCsv() {
    return this.http.get<any>(this.cryptoTransactionapi + "?exportToCSV=1", { headers: {}, responseType: 'blob' as 'json' });
  }

  getSvdTransactionHistory(dataParams: any): Observable<{}> {
    return this.http.get(`${this.svdTransactionapi}`, {
      params: dataParams,
    });
  }

  getDownloadSvdCsv() {
    return this.http.get<any>(this.svdTransactionapi + "?exportToCSV=1", { headers: {}, responseType: 'blob' as 'json' });
  }

  getCustodyReportApi(dataParams: any): Observable<{}> {
    return this.http.get(`${this.custodyReportApi}`, {
      params: dataParams,
    });
  }

  getDownloadCustodyCsv(dataParams: any) {
    return this.http.get<any>(this.custodyReportApi, { 
      headers: {}, 
      params: dataParams,
      responseType: 'blob' as 'json' 
    });
  }

  getDownloadFmaCsv(dataParams: any) {
    return this.http.get<any>(this.fmaReportApi, { 
      headers: {}, 
      params: dataParams,
      responseType: 'blob' as 'json' 
    });
  }

  // getDownloadFmaCsv(dataParams) {
	// 	return this.http.get<any>(this.fmaReportApi, {headers: {}, params: dataParams, });
	// }

  getkycApplicant(pathParams: any, dataParams: any): Observable<{}> {
    return this.http.get(`${this.kycapplicant + pathParams}`, {
      params: dataParams,
    });
  }

  getChangePassword(dataParams: any) {
    return this.http.put<any>(this.changepasswordapi, dataParams).pipe(
      map((user) => {
        this.currentUserSubject.next(null);
        return user;
      })
    );
  }

  getUpdateKyc(pathParam: any, dataParams: any) {
    return this.http.put<any>(this.kycstatusapi + pathParam, dataParams).pipe(
      map((user) => {
        this.currentUserSubject.next(null);
        return user;
      })
    );
  }

  logout(dataParams: any) {
    // remove user from local storage to log user out
    return this.http.post<any>(this.logoutapi, dataParams).pipe(
      map((user) => {
        localStorage.removeItem('admin_access_token');
        localStorage.removeItem('client_access_token');
        localStorage.removeItem('current_user');
        this.currentUserSubject.next(null);
        return user;
      })
    );
  }


  getUserByEmail(dataParams: any): Observable<{}> {
    return this.http.get(`${this.userByEmail}`, {
      params: dataParams,
    });
  }

  getUserById(dataParams: any): Observable<{}> {
    return this.http.get(`${this.userById}`, {
      params: dataParams,
    });
  }

  getMigrateUser(dataParams: any) {
    return this.http.post<any>(this.migrateUser, dataParams).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  getUserByTicket(dataParams: any): Observable<{}> {
    return this.http.get(`${this.userByTicket}`, {
      params: dataParams,
    });
  }

  TerminateAcc(dataParams: any) {
    return this.http.post<any>(this.terminateAcc, dataParams).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  ResumeUser(dataParams: any) {
    return this.http.post<any>(this.resumeUser, dataParams).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  getKycManualReview(dataParams: any): Observable<{}> {
    return this.http.get(`${this.kycManualReview}`, {
      params: dataParams,
    });
  }

  VerifyAccSkrill(dataParams: any) {
    return this.http.post<any>(this.verifyAccSkrill, dataParams).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  getVerifyAccBank(dataParams: any): Observable<{}> {
    let url = this.verifyAccBank + "/" + dataParams;
    return this.http.get(url);
  }


  getMigrationToolListApi(dataParams: any): Observable<{}> {
    return this.http.get(`${this.migrationToolListapi}`, {
      params: dataParams,
    });
  }

  // VerifyAccSkrill(dataParams: any, token: any) {
  //   return this.http.post<any>(this.verifyAccSkrill, dataParams, {headers: {Authorization: token}}).pipe(
  //     map((resp) => {
  //       return resp;
  //     })
  //   );
  // }

  // getVerifyAccBank(dataParams: any, token: any): Observable<{}> {
  //   let url = this.verifyAccBank + "/" + dataParams;
  //   return this.http.get(url, {headers: {Authorization: token}});
  // }

  updateSuspect(dataParams: any) {
    return this.http.put<any>(this.suspectupdateapi, dataParams).pipe(
      map((user) => {
        return user;
      })
    );
  }

  updateRemidiation(dataParams: any, pathParam: any) {
    return this.http.put<any>(this.remidiationupdateapi + '/' + pathParam, dataParams).pipe(
      map((user) => {
        return user;
      })
    );
  }

  updateOnBoarding(dataParams: any, pathParam: any) {
    return this.http.put<any>(this.onBoardingupdateapi + '/' + pathParam, dataParams).pipe(
      map((user) => {
        return user;
      })
    );
  }
  
  getAddBtcTransferDiv(dataParams: any) {
    return this.http.post<any>(this.addBtcTransfer, dataParams).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

}
