import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './share/guard/auth.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    }
    // {
    //     path: 'forgot-password',
    //     loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
    // },
    // {
    //     path: 'dashboard',
    //     loadChildren: () => import('./layout/dashboard/dashboard.module').then(m => m.DashboardModule)
    // }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule {}
