import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer} from '@angular/platform-browser';
import {FormControl, FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { AuthServices } from '../../services/index';
import { Config } from 'protractor';
import { ActivatedRoute, Router } from '@angular/router';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.css']
})
export class UserDialogComponent implements OnInit{
  title: string;
  message: any;
  user: any;
  emailForm: any;
  idForm: any;
  ticketForm: any;
  submitted: boolean = false;
  // isValidEmail: boolean = true;
  // isValidTicket: boolean = true;

  // get form control
  get form() {
    return this.emailForm.controls;
  }

  get f() {
    return this.idForm.controls;
  }
  // public showTable = false;

  constructor(
    private authServices: AuthServices,
    public sanitizer: DomSanitizer, 
    private router: Router,
    public dialogRef: MatDialogRef<UserDialogComponent>,
  ) {}

  // @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

    // form controls
    let idFormcontrols = {
      id: new FormControl('', [Validators.required]),
    };

    let emailFormcontrols = {
      email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
    };

    let ticketFormcontrols = {
      ticket: new FormControl('', [Validators.required]),
    };

    // create a form
    this.idForm = new FormGroup(idFormcontrols);
    this.emailForm = new FormGroup(emailFormcontrols);
    this.ticketForm = new FormGroup(ticketFormcontrols);

  }


  onIdSubmit() {
      if (this.idForm.invalid) {
        return;
      }

      const dataParams = {
        id: this.idForm.value.id.trim()
      };
      // const error = document.getElementById("user_error");
      
      this.authServices.getUserById(dataParams).subscribe(
        (resp: Config) => {
          if (resp.code === 200) {
            this.dialogRef.close(resp.data);
          } else{
            // alert(resp.data);
            // error.innerHTML = resp.data;
          }
        },
        (err) => {
          console.log('status --->' + err.error.error);
        }
      );
  }

  onEmailSubmit() {
    // this.isValidEmail = false;

      if (this.emailForm.invalid) {
        return;
      }

      const dataParams = {
        email: this.emailForm.value.email.trim()
      };
      // const error = document.getElementById("user_error");
      
      this.authServices.getUserByEmail(dataParams).subscribe(
        (resp: Config) => {
          if (resp.code === 200) {
            this.dialogRef.close(resp.data);
          } else{
            // alert(resp.data);
            // error.innerHTML = resp.data;
          }
        },
        (err) => {
          console.log('status --->' + err.error.error);
        }
      );
  }


  onTicketSubmit(){

      if (this.ticketForm.invalid) {
        return;
      }

      const dataParams = {
        ticket_no: this.ticketForm.value.ticket.trim()
      };
      // const error = document.getElementById("user_error");
      
      this.authServices.getUserByTicket(dataParams).subscribe(
        (resp: Config) => {
          if (resp.code === 200) {
            this.dialogRef.close(resp.data);
          } else{
            // error.innerHTML = resp.data;
          }
        },
        (err) => {
          console.log('status --->' + err.error.error);
        }
      );
  }

}

export class UserDialogModel {

  constructor(public title: string, public message: string) {
  }
}
