<h1 mat-dialog-title>
    {{message.doc_type.replace('_', ' ') | titlecase }}
</h1>

<!-- <div mat-dialog-content>
    <object width="100%" [data]="sanitizer.bypassSecurityTrustResourceUrl(message.url)" type="{{message.content_type}}"></object>
</div> -->

<div mat-dialog-content *ngIf="message.content_type === 'image/jpeg' || message.content_type === 'image/png'">
    <img src="{{message.url}}">
</div>

<div mat-dialog-content *ngIf="message.content_type !== 'image/jpeg' && message.content_type !== 'image/png'">
    <object width="100%" [data]="sanitizer.bypassSecurityTrustResourceUrl(message.url)" type="{{message.content_type}}"></object>
</div>

<div mat-dialog-actions>
    <button mat-raised-button color="primary" mat-button mat-dialog-close>Ok</button>
</div>