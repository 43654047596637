import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthServices } from '../../services/index';
import { Config } from 'protractor';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-addCrypto-dialog',
  templateUrl: './addCrypto-dialog.component.html',
  styleUrls: ['./addCrypto-dialog.component.css']
})
export class AddCryptoDialogComponent implements OnInit {
  title: string;
  message: any;
  user: any;
  cryptoForm: any;
  submitted: boolean = false;
  public addBtnDisabled: boolean = false;

  // get form control
  get form() {
    return this.cryptoForm.controls;
  }

  constructor(
    private authServices: AuthServices,
    public sanitizer: DomSanitizer,
    private router: Router,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddCryptoDialogComponent>) {
    // this.title = data.title;
    // this.message = data.message;
  }

  ngOnInit(): void {

    // form controls
    let cryptoFormcontrols = {
      userId: new FormControl('', [Validators.required]),
      cryptoValue: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      comment: new FormControl('', [Validators.required]),
    };

    // create a form
    this.cryptoForm = new FormGroup(cryptoFormcontrols);

  }

  openSnackBar(message: any) {
    this._snackBar.open(message, '', {
      duration: 3000,
    });
  }

  onCryptoSubmit() {
    if (this.cryptoForm.invalid) {
      return;
    }

    const dataParams = {
      crypto_value: this.cryptoForm.value.cryptoValue,
      type: this.cryptoForm.value.type,
      comment: this.cryptoForm.value.comment,
      user_id: Number(this.cryptoForm.value.userId)
    };

    this.authServices.addCrypto(dataParams).subscribe(
      (resp: Config) => {
        if (resp.code === 200) {
          this.dialogRef.close({click: "submitClick"});
          this.openSnackBar(resp.message);
        } else {
          // error.innerHTML = resp.data;
        }
      },
      (err) => {
        console.log('status --->' + err.error.error);
      }
    );
  }

  cancel() {
    this.dialogRef.close({click: "cancelClick"});
  }

}

export class AccTerminateDialogModel {
  constructor(public title: string, public message: string) {
  }
}
