import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-proofs-dialog',
  templateUrl: './proofs-dialog.component.html',
  styleUrls: ['./proofs-dialog.component.css']
})
export class ProofsDialogComponent implements OnInit {
  title: string;
  message: any;

  constructor(public sanitizer: DomSanitizer, public dialogRef: MatDialogRef<ProofsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProofDialogModel) {
      this.title = data.title;
      this.message = data.message;
     }

  ngOnInit(): void {
  }

}

export class ProofDialogModel {

  constructor(public title: string, public message: string) {
  }
}
