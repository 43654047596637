import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer} from '@angular/platform-browser';
import {FormControl, FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { AuthServices } from '../../services/index';
import { Config } from 'protractor';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UrlWithStringQuery } from 'url';

@Component({
  selector: 'app-applicantData-dialog',
  templateUrl: './applicantData-dialog.component.html',
  styleUrls: ['./applicantData-dialog.component.css']
})
export class ApplicantDataDialogComponent implements OnInit{

  applicantData: any = [];

  constructor(
    private authServices: AuthServices,
    public sanitizer: DomSanitizer, 
    private router: Router,    
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: ApplicantDataDialogModel,
    public dialogRef: MatDialogRef<ApplicantDataDialogComponent>) {
      this.applicantData = data;
    }

  ngOnInit(): void {}

  openSnackBar(message: any) {
    this._snackBar.open(message, '', {
      duration: 3000,
    });
  }

}

export class ApplicantDataDialogModel {

  constructor(public applicantData: any) {
  }
}
