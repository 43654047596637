import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,  HttpErrorResponse, HttpResponseBase } from '@angular/common/http';
import { Observable, of, throwError  } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Config } from 'protractor';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthServices } from '../services/index';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private _snackBar: MatSnackBar, private authServices: AuthServices, private router: Router) { }

    private checkStatus(ev: HttpResponseBase) {
        if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
          return;
        }
                // const errortext = CODEMESSAGE[ev.status] || ev.statusText;
         // alert(`Request error${ev.status}: ${ev.url}`);
         // this.notification.error(`Request error${ev.status}: ${ev.url}`, errortext);
      }

      private handleData(ev: HttpResponseBase): Observable<any> {
        // if (ev.status > 0) {
        //   this.injector.get(_HttpClient).end();
        // }
        this.checkStatus(ev);
        if (ev['body']) {
          switch (ev['body']['code']) {
            case 200:
              break;
            case 400:
              if (this.router.url !== '/login') {
                this.openSnackBar(`${ev['body']['message']}`);
              }
              break;
            case 401:
                this.openSnackBar(`${ev['body']['message']}`);
                const dataParams = {};
                this.authServices.logout(dataParams).subscribe((response: Config) => {
                  this.router.navigate(['/login']);
                });
              break;
            case 403:
            case 404:
            case 410:
             this.openSnackBar(`${ev['body']['message']}`);
              break;
            case 409:
               // this.openSnackBar(`${ev['body']['message']}`);
              break;
            case 500:
                this.openSnackBar(`${ev['body']['message']}`);
              // this.router.navigate(['/page-not-found']);
              break;
            default:
              if (ev instanceof HttpErrorResponse) {
                // tslint:disable-next-line:max-line-length
                console.warn(`I don't know the error, most of it is caused by the backend not supporting CORS or invalid configuration.`, ev);
                return throwError(ev);
              }
              break;
          }
        }
        return of(ev);
      }

      intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = req.url;
        // if (!url.startsWith('https://') && !url.startsWith('http://')) {
        //   url = environment.apiUrl + url;
        // }
        const newReq = req.clone({ url });
        return next.handle(newReq).pipe(
          mergeMap((event: any) => {
            if (event instanceof HttpResponseBase) { return this.handleData(event); }
            return of(event);
          }),
          catchError((err: HttpErrorResponse) => this.handleData(err)),
        );
      }

      openSnackBar(message: any) {
        this._snackBar.open(message, '', {
          duration: 3000,
        });
      }

}
