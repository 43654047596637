<h1 mat-dialog-title>
    Enter account termination details
</h1>

<div mat-dialog-content>
    <form class="example-form" [formGroup]="terminateForm" (ngSubmit)="onTerminateSubmit()">
        <mat-form-field class="example-full-width">
            <mat-label>User Email</mat-label>
            <input id="email" type="email" name="email" formControlName="email" matInput placeholder="Enter User Email">
        </mat-form-field>
        <!-- <div id="user_error"></div> -->
        <div *ngIf="(submitted && form.email.errors) || (form.email.errors && (form.email.dirty || form.email.touched))" class="error">
            <p class="error" *ngIf="form.email.errors.required">Email is required</p>
            <p class="error" *ngIf="form.email.errors.pattern">Invalid email</p>
        </div>

        <!-- <div class="radio-box">
            <mat-label>Payout method</mat-label>
            <label class="form-check-inline">
                <input class="form-check-input" formControlName="payout" type="radio" name="payout" value="bank">Bank account
            </label>
            <label class="form-check-inline">
                <input class="form-check-input" type="radio" formControlName="payout" name="payout" value="skrill">Skrill account
            </label>
        </div> -->
        <mat-grid-list cols="5" gutterSize="15" rowHeight="80px">
            <mat-grid-tile [colspan]="2">
                <mat-form-field class="example-full-width">
                    <mat-label>Payout method</mat-label>
                    <mat-select name="payout" formControlName="payout" (selectionChange)="payoutChange($event.value)" matSelect>
                        <mat-option>None</mat-option>
                        <mat-option value="bank">Bank account</mat-option>
                        <mat-option value="skrill">Skrill account</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="2">
                <mat-form-field class="example-full-width">
                    <mat-label>IBAN or Skrill account</mat-label>
                    <input matInput id="iban" type="text" name="iban" formControlName="iban" placeholder="Enter IBAN">
                </mat-form-field>
                <!-- <div *ngIf="(submitted && form.iban.errors) || (form.iban.errors && (form.iban.dirty || form.iban.touched))" class="error">
                    <p class="error" *ngIf="form.iban.errors.required">IBAN is required</p>
                </div> -->
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1">
                <button mat-raised-button color="primary" type="button" [disabled]="!this.terminateForm.value.iban || !this.terminateForm.value.payout || this.terminateForm.value.payout === undefined" (click)="verifyAcc()" mat-button>Verify Account</button>
            </mat-grid-tile>
        </mat-grid-list>
        <div class="verify_succ" *ngIf="verifySuccessMessage">Account Successfully Verified</div>
        <div class="verify_fail" *ngIf="verifyFailMessage">Account Verification Failed</div>


        <mat-form-field class="example-full-width">
            <mat-label>Ticket No.</mat-label>
            <input matInput id="ticket" type="text" name="ticket" formControlName="ticket" matInput placeholder="Enter Ticket No. or Comment">
        </mat-form-field>
        <div *ngIf="(submitted && form.ticket.errors) || (form.ticket.errors && (form.ticket.dirty || form.ticket.touched))" class="error">
            <p class="error" *ngIf="form.ticket.errors.required">Tickets No is required</p>
        </div>

        <mat-form-field class="example-full-width">
            <mat-label>Remarks</mat-label>
            <input matInput id="remark" type="text" name="remark" formControlName="remark" matInput placeholder="Enter Remarks">
        </mat-form-field>
        <!-- <div *ngIf="(submitted && form.remark.errors) || (form.remark.errors && (form.remark.dirty || form.remark.touched))" class="error">
            <p class="error" *ngIf="form.remark.errors.required">Remarks is required</p>
        </div> -->

        <div class="btn-toolbar">
            <button mat-raised-button color="primary" type="submit" [disabled]="trminateAccBtnDisabled" mat-button>
                Terminate now
            </button>
        </div>
    </form>
</div>
<!-- <div mat-dialog-actions style="float: right;">
    <button mat-raised-button color="primary" type="submit" mat-button>Terminate now</button>
</div> -->