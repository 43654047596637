import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServices } from '../services/index';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor( private router: Router,
        private authenticationService: AuthServices) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // if (localStorage.getItem('ACCESS_TOKEN')) {
        //     return true;
        // }

        // this.router.navigate(['/login']);
        // return false;
        const getAccessToken = this.authenticationService.getAccessToken();
        if (getAccessToken) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
    }
}
