<!-- <h1 mat-dialog-title>
    Suspect Status
</h1> -->

<div mat-dialog-content class="search-tab-wrapper">
    <form class="example-form" [formGroup]="suspectForm" (ngSubmit)="onSubmit()">
        <div>
            <mat-form-field>
                <mat-select #matSelect placeholder="Suspect Status" formControlName="suspectStatus">
                    <mat-option value="null">To Check</mat-option>
                    <mat-option value="reported">Reported</mat-option>
                    <mat-option value="verified">Not Reported</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <textarea matInput formControlName="suspectRemark" value="" placeholder="Suspect Remark"></textarea>
            </mat-form-field>
            <div *ngIf="(submitted && form.suspectRemark.errors) || (form.suspectRemark.errors && (form.suspectRemark.dirty || form.suspectRemark.touched))" class="error">
                <p class="error" *ngIf="form.suspectRemark.errors.required">Suspect Remark is required</p>
            </div>
        </div>

        <div class="btn-toolbar">
            <button mat-raised-button color="primary" type="submit" mat-button>
                Submit
            </button>
            <!-- <button mat-raised-button color="warn" mat-button (click)="onCancel();" style="margin-left: 10px;">
                Cancel
            </button> -->
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="warn" mat-button (click)="onCancel();" style="margin-left: 10px;">
        Cancel
    </button>
</div>