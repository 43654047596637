import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
})

export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  selectedValue: any;
  remarkValue: any = '';
  remarkForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    // Update view with given values
    this.title = data.title;
    const objMessage = JSON.parse(data.message);
    this.message = objMessage.textMessage;
    this.selectedValue = objMessage.selectedAction;
  }

  ngOnInit(): void {
    this.remarkForm = this.formBuilder.group(
      {
        remarks: ['', [Validators.required, Validators.maxLength(500), this.noWhitespaceValidator]]
      }
    );
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

  onConfirm(): void {
      // Close the dialog, return true
    if (this.selectedValue === 'declined') {
      if (this.remarkForm.invalid) {
        return;
      }
      this.remarkValue = this.remarkForm.get('remarks').value.trim();
    }
     const closeObj = {status: true, remarkText: this.remarkValue };
    this.dialogRef.close(closeObj);
  }

  onDismiss(): void {
    // Close the dialog, return false
    const closeObj = {status: false, remarkText: this.remarkValue };
    this.dialogRef.close(closeObj);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of share component.
 */
export class ConfirmDialogModel {
  constructor(public title: string, public message: string) {}
}
